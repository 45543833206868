<div class="jumbotron" style="text-align: center;">
  <h3>Turma</h3>
</div>
<div>
    <table class="table table-condensed table-hover">
        <thead>
            <tr>
                <th>Filtros</th>
                <th style="width: 30%">
                    <select id="selectOrder" class="custom-select" (change)="orderBy($event)">
                        <option value="escolaAsc" selected>A-Z ESCOLAS</option>
                        <option value="escolaDesc">Z-A ESCOLAS</option>
                        <option value="dataDesc">DATA MAIS RECENTE</option>
                        <option value="dataAsc">DATA MAIS ANTIGA</option>
                    </select>
                </th>
            </tr>
        </thead>
    </table>
</div>
<form [formGroup]="formFiltro" (ngSubmit)="search()">
  <table class="table table-condensed table-hover">
    <thead>
      <tr>
        <th id="clEscola">Escola
            <select id="escolaId" class="custom-select" formControlName="escolaId">
            <option value="" selected>--</option>
            <option *ngFor="let escola of escolas" [value]="escola.id">{{escola.nome}}</option>
          </select>
        </th>
        <th id="clSituacaoAgendamento">Situação
          <select id="dominioIdSituacaoAgendamento" class="custom-select" formControlName="dominioIdSituacaoAgendamento">
            <option value="" selected>--</option>
            <option *ngFor="let dominio of situacoesAgendamento" [value]="dominio.id">{{dominio.descricao}}</option>
          </select>
        </th>
        <th id="clDataAgendada">Data Agendada
          <input type="date" class="form-control" id="dataAgendada" formControlName="dataAgendada"
            placeholder="Data Agendada" />
        </th>
        <th id="clPeriodo">Período
          <div class="form-control border-0">-</div>
        </th>
        <th id="clSerie">Ano(Série)
          <div class="form-control border-0">-</div>
        </th>
        <th id="clNumeroVisitantes">Nº Visitantes
          <div class="form-control border-0">-</div>
        </th>
        <th id="clBotoes">
          <button type="submit" class="btn btn-outline-secondary float-right">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                <path fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
            </svg>
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let agendamento of agendamentos">
        <td>{{agendamento.escola}}</td>
        <td>{{agendamento.situacaoAgendamento}}</td>
        <td>{{agendamento.dataAgendada | date: 'dd/MM/yyyy'}}</td>
        <td>{{agendamento.periodo}}</td>
        <td>{{formatarSeries(agendamento) }}</td>
        <td>{{agendamento.numeroVisitantes}}</td>
        <td>
          <div class="dropdown float-right">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="drodownMenuActions"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuActions">
              <button type="button" class="btn btn-outline-secondary dropdown-item" [disabled]="agendamento.cancelado" placement="bottom" routerLink="../criar/{{agendamento.agendamentoId}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                  <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z"/>
                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                </svg>
                Cadastrar
              </button>
              <button type="button" class="btn btn-outline-secondary dropdown-item" placement="bottom" routerLink="../visualizar/{{agendamento.agendamentoId}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                </svg>
                Visualizar
              </button>
              <button type="button" class="btn btn-outline-secondary dropdown-item" [disabled]="agendamento.cancelado" placement="bottom" (click)="remove(agendamento.agendamentoId)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <span *ngIf="agendamento.cancelado"> Agendamento cancelado</span>
                <span *ngIf="!agendamento.cancelado"> Apagar</span>
              </button>
              <button *ngIf="!isOperadorEscola" type="button" class="btn btn-outline-secondary dropdown-item" [disabled]="agendamento.cancelado" placement="bottom" routerLink="../checklist/{{agendamento.agendamentoId}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
                Checklist
              </button>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="agendamentos.length == 0">
        <td colspan="7" align="center">
          Nenhum registro encontrado.
        </td>
      </tr>
    </tbody>
  </table>
</form>
<app-pagination *ngIf="agendamentos.length != 0" [totalSize]="totalSize" [(page)]="page" [pageSize]="pageSize"
  (pageChange)="pageChange()"></app-pagination>
