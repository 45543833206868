import { ServiceLocator } from 'src/app/services/locator.service';
import { MessageService } from "src/app/services/message.service";
import { environment, GLOBAL } from 'src/environments/environment';
import { Escola } from 'src/app/models/escola'
import { PerfilEnum } from './perfil.enum';

export class DtnAuthUserWrapper {

    private readonly URL_VALIDAR_TOKEN:string = `${environment.apiBaseHost}detran/validartokenacesso?uid=`;
    private readonly URL_CHECK_ESCOLA:string = `${environment.apiBaseUrl}/escola/checkEscola`;

    token: string;
    codigoPerfil: string;
    cnpjEscola: number;
    escola: Escola;
    nomeEscola: string;
    nomeUsuario: string;
    login: string;
    qcaCodigo: number;
    qcaDescricao: string;

    public messageService;

    constructor(token: string) {
        console.log('DtnAuthUserWrapper.constructor(token)', token, ServiceLocator.injector);
        this.messageService = ServiceLocator.injector.get(MessageService);
        this.token = token;
        if (this.validarTokenAcesso()) {
            if (this.isOperadorEscola()) {
                this.checkEscola();
            }
        } else {
            throw new Error('Erro ao validar o token de acesso');
        }
    }

    private validarTokenAcesso(): boolean {
        console.log('DtnAuthUserWrapper.validarTokenAcesso()', this.token);
        var url = `${this.URL_VALIDAR_TOKEN}` + this.token + '&param=addemp';
        var request = new XMLHttpRequest();
        request.open('GET', url, false);
        request.send();
        if (request.status === 200) {
            var dtnuser = request.response;
            dtnuser = JSON.parse(dtnuser);
            console.log('DtnAuthUserWrapper.validarTokenAcesso()', dtnuser);
            
            if(dtnuser['codigo'] === null || dtnuser['codigo'] === undefined
                || dtnuser['codigoPerfil'] === null || dtnuser['codigoPerfil'] === undefined){
                this.messageService.tratarMensagemErro3(false, 'Dados do usuário não encontrados. Se persistir o problema, contate o suporte.', () => {});
                
                return false;
            }
            
            var codigo = dtnuser['codigo'];
            if (codigo === '000') {
                this.codigoPerfil = dtnuser['codigoPerfil'];
                this.nomeUsuario = dtnuser['nome'];
                this.login = dtnuser['login'];
                
                if (this.codigoPerfil === '30' && (dtnuser['qcaCodigo'] === null || dtnuser['qcaCodigo'] === undefined
                    || dtnuser['qcaDescricao'] === null || dtnuser['qcaDescricao'] === undefined)) {
                    this.messageService.tratarMensagemErro3(false, 'Dados da escola não encontrados. Se persistir o problema, contate o suporte.', () => { });

                    return false;
                }
                
                this.qcaCodigo = dtnuser['qcaCodigo'];
                this.qcaDescricao = dtnuser['qcaDescricao'];
                return true;
            } else {
                this.messageService.tratarMensagemErro3(false, dtnuser['mensagem'], () => {
                    parent.postMessage({actions: ['goHome', 'dstvLoading']}, '*')
                });
                return false;
            }
        }
        return false;
    }

    private checkEscola(): void {
        var escolaFindDTO = new Escola();
        
        escolaFindDTO.setNome(this.qcaDescricao);
        escolaFindDTO.setCodigo(this.qcaCodigo);
        
        var data = JSON.stringify(escolaFindDTO);
        console.log('DtnAuthUserWrapper.checkEscola()', data);
        
        var url = `${this.URL_CHECK_ESCOLA}`;
        var request = new XMLHttpRequest();
        
        request.open('PUT', url, false);
        request.setRequestHeader("Content-Type", "application/json");
        request.send(data);
        
        if (request.status === 200) {
            var response = request.response;
            var instituicao = JSON.parse(response);
            console.log('DtnAuthUserWrapper.checkEscola() - response: ', instituicao);
            this.escola = new Escola();
            this.escola.setId(instituicao.id);
            this.escola.setCnpj(instituicao.cnpj);
            this.escola.setNome(instituicao.nome);
            this.escola.setCodigo(instituicao.codigo);
        } else {
            console.log('DtnAuthUserWrapper.checkEscola() - Error(status|text): ', request.status);
            this.messageService.tratarMensagemErro2(false, request.responseText, () => {
                parent.postMessage({actions: ['goHome', 'dstvLoading']}, '*')
            });
        }
    }

    public getCodigoPerfil() : string {
        return this.codigoPerfil;
    }

    public getCnpjEscola() : number {
        return this.cnpjEscola;
    }

    public isOperadorEscola() : boolean {
        return this.codigoPerfil != null && this.codigoPerfil === '30';
    }

    public getInstituicaoEscola(): Escola {
        return this.escola;
    }

    public setInstituicaoEscola(escola: Escola): void {
        this.escola = escola;
    }
    
    public getNomeUsuario(): string {
        return this.nomeUsuario;
    }
    
    public getLogin(): string {
        return this.login;
    }
    
    public getCodigoEscola() : number {
        return this.qcaCodigo;
    }
    
    public temAcesso(): boolean {
        return Object.values(PerfilEnum).includes(this.codigoPerfil as PerfilEnum);
    }

}